<script setup lang="ts">
import { lightenDarkenColor } from '@gem/control';

const props = defineProps<{ color?: string; myColors: string[] }>();
const emits = defineEmits(['onPickColor']);

const selected = (color: string) => {
  emits('onPickColor', color);
};

const isActive = (myColor: string) => {
  return myColor === props.color;
};
</script>

<template>
  <div class="">
    <div class="text-dark-high text-12 px-4 pt-16 pb-4">My colors</div>
    <div data-test="editor-control-my-colors" class="mt-4 grid grid-cols-8 gap-y-12">
      <div v-for="myColor in myColors" :key="myColor">
        <div
          data-test="editor-control-my-color-option"
          class="group flex h-36 w-36 cursor-pointer items-center justify-center"
          @click="selected(myColor)">
          <div
            class="flex h-32 w-32 items-center justify-center rounded-full border border-transparent"
            :class="[
              { '!border-primary-300 ': isActive(myColor) },
              { 'group-hover:border-dark-high group-hover:h-[30px] group-hover:w-[30px]': !isActive(myColor) },
            ]">
            <div class="relative h-24 w-24 rounded-full">
              <div
                class="gemx-transparent-bg absolute top-1/2 left-1/2 h-[21px] w-[21px] -translate-x-1/2 -translate-y-1/2 transform rounded-full border"
                :style="{
                  'border-color': lightenDarkenColor(myColor),
                }" />
              <div
                :style="{
                  'background-color': myColor,
                  'border-color': lightenDarkenColor(myColor),
                }"
                class="absolute top-1/2 left-1/2 h-[21px] w-[21px] -translate-x-1/2 -translate-y-1/2 transform rounded-full border" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.gemx-transparent-bg {
  background-image: url('/images/transparent-color.svg');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
