<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div class="gt_control-textarea">
      <textarea
        ref="textarea"
        v-model="val"
        class="textarea scroll-bar !text-12"
        :class="inputClass"
        type="text"
        :placeholder="placeholder || ''"
        :disabled="readonly"
        @input.stop="onChange"
        @change.stop="change"
        @focus.stop="focus"
        @wheel.stop
        @blur.stop="blur" />
    </div>
    <slot name="info"></slot>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';

type PropsType = {
  id?: string | number;
  placeholder?: string;
  value?: any;
  minHeight?: number;
  maxHeight?: number;
  readonly?: boolean;
  inputClass?: string;
  autoHeight?: boolean;
};
const props = withDefaults(defineProps<PropsType>(), {
  id: '',
  value: [],
  options: [
    {
      label: 'Unset',
      value: 'unset',
    },
  ],
  minHeight: 50,
  maxHeight: 300,
  readonly: false,
});
const textarea = ref<HTMLAreaElement>();

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
  (e: 'clickDisabled'): void;
}>();

const val = ref(props.value);
const initialSize = ref(0);
// const currentSize = ref(0);

watch(
  () => props.value,
  (value) => {
    setValue(value);
  },
);

watch(val, () => {
  autosize();
});

watch(
  () => props.minHeight,
  (oldVal, newValue) => {
    if (textarea.value) {
      textarea.value.style.minHeight = newValue + 'px';
    }
  },
);

watch(
  () => props.maxHeight,
  (value) => {
    if (textarea.value) {
      textarea.value.style.minHeight = value + 'px';
    }
  },
);

// val.value = props.value;

onMounted(() => {
  setRangeHeight();
  if (textarea.value) {
    initialSize.value = textarea.value.clientHeight;
  }
});

const setValue = (value: any) => {
  if (value != val.value) {
    val.value = value;
    autosize();
  }
};
const onChange = () => {
  if (props.readonly) {
    return;
  }
  emit('controlOnChange', props.id, val.value);
};
const change = () => {
  if (props.readonly) {
    return;
  }
  if (val.value) {
    val.value = val.value.trim();
  }
  emit('controlChange', props.id, val.value);
};
// const triggerFocus = () => {
//   if (textarea.value) {
//     textarea.value?.focus();
//   }
// };
const focus = () => {
  emit('controlFocus', props.id, val.value);
};
const blur = () => {
  emit('controlBlur', props.id, val.value);
};
const autosize = () => {
  if (textarea.value) {
    const textareaClone = textarea.value;
    if (textareaClone) {
      textareaClone.style.height = 'auto';
      textareaClone.style.height = `${textareaClone?.scrollHeight + 2}px`;
    }
  }
};
const setRangeHeight = () => {
  const $textarea = textarea.value;
  if (!$textarea) return;
  if (props.autoHeight) {
    autosize();
  } else {
    if (props.minHeight) {
      $textarea.style.minHeight = props.minHeight + 'px';
    }
    if (props.maxHeight && $textarea) $textarea.style.maxHeight = props.maxHeight + 'px';
  }
};
</script>

<style lang="css" scoped>
.textarea {
  @apply rounded-medium bg-dark-400 hover:bg-dark-200 text-14 font-regular text-dark-high box-border block w-full resize-none overflow-hidden border border-transparent p-8 outline-none;
  @apply placeholder:text-dark-disabled focus:border-primary-250 hover:border-transparent;
  @apply disabled:bg-dark-200 disabled:text-dark-disabled disabled:hover:border-dark-200 disabled:cursor-not-allowed disabled:select-none;
  @apply !max-h-[248px] overflow-y-auto;
}

.scroll-bar::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #757575;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.scroll-bar::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
