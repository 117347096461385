<script setup lang="ts">
import { lightenDarkenColor, isColor, GlobalPicker } from '@gem/control';
import { onClickOutside } from '@vueuse/core';
import SuggestedForYou from './SuggestedForYou.vue';
import MyColorComponent from './MyColors.vue';
import { computed, onUnmounted, ref, nextTick } from 'vue';
import { useFloating, offset, shift, autoUpdate } from '@floating-ui/vue';
import type { ColorProps, ColorType } from '../types';
import useCurrentGlobalColors from 'gemx/src/modules/editor/modules/settings/hooks/useCurrentGlobalColors';
import useGlobalStyleStore from 'gemx/src/modules/editor/modules/sidebar/stores/globalStyle';
import useSaveGlobalStyle from 'gemx/src/modules/editor/modules/sidebar/hooks/useSaveGlobalStyle';
import useCurrentGlobalStyle from 'gemx/src/modules/editor/modules/sidebar/hooks/useCurrentGlobalStyle';
import colorTransparentImg from './images/color-transparent-preview.png';

const DEFAULT_COLOR = ['#121212', '#4F4F4F', '#E2E2E2', '#FFFFFF', '#334FB4'];
const SUGGESTED_FOR_YOU = ['#000000', '#333333', '#555555', '#777777', '#999999', '#BBBBBB', '#DDDDDD', '#FFFFFF'];
const MAX_COLOR_SIZE = 32;
const START_INDEX_REPLACED = 5;

const props = defineProps<{
  value?: ColorType | string;
  contained?: boolean;
  id: string;
}>();

const emits = defineEmits<{
  (e: 'controlChange', controlId: string, value?: any): void;
  (e: 'controlOnChange', controlId: string, value?: any): void;
}>();

const isFixed = ref(false);
const reference = ref<HTMLElement | null>(null);
const floating = ref<HTMLElement | null>(null);
const { y, strategy } = useFloating(reference, floating, {
  placement: 'bottom',
  middleware: [offset(9), shift()],
  whileElementsMounted: autoUpdate,
});

const spaceBelow = ref(0);
const globalStyleStore = useGlobalStyleStore();
const { save, setGlobalStyle } = useSaveGlobalStyle();
const globalColors = useCurrentGlobalColors();

const pickValue = ref(
  props.value && isColor(props.value) ? props.value : globalColors.find((col) => col.colorType === props.value)?.color,
);

const isChange = ref(false);
const isOpenSelectedColor = ref(false);
const showColorPreset = ref(false);
const maybeShowColorPreset = ref(false);
const onlyAddMore = ref(false);
const options = computed<ColorProps>(() => {
  if (!props.value) {
    return {
      colorType: 'custom',
      color: '',
    };
  }

  if (isColor(props.value)) {
    return {
      colorType: 'custom',
      color: props.value,
    };
  }

  return {
    color: globalColors.find((col) => col.colorType === props.value)?.color,
    colorType: props.value as ColorType,
  };
});

const globalStyle = useCurrentGlobalStyle();
const colorIndexChange = computed(() => (globalStyle.value && globalStyle.value.color['color-index-change']) ?? 0);
const changeIndex = ref(colorIndexChange.value <= 0 ? START_INDEX_REPLACED : colorIndexChange.value);

const findManyColor = (): string[] => {
  if (!globalStyle.value || !globalStyle.value.color) {
    return DEFAULT_COLOR.map((c) => c.toLowerCase());
  }

  if (globalStyle.value.color['my-colors'] && globalStyle.value.color['my-colors'].length > 0) {
    const colors = globalStyle.value.color['my-colors']
      .concat(globalStyle.value.color['theme-colors'] as string[])
      .filter(Boolean);

    return colors.map((c: string) => {
      if (isColor(c)) {
        return c;
      } else {
        return globalColors.find((col) => col.colorType === c)?.color;
      }
    });
  }

  return DEFAULT_COLOR.map((c) => c.toLowerCase());
};

const globalColor = ref<string[]>(findManyColor());
const myColors = ref([...new Set(globalColor.value)].filter((color) => color !== 'transparent'));
const hasMyColor = computed(() => {
  return myColors.value.find((color) => color.toUpperCase() === pickValue.value?.toUpperCase());
});

const hasSuggestColor = computed(() =>
  SUGGESTED_FOR_YOU.find((color) => color.toLowerCase() === pickValue.value?.toLowerCase()),
);

const backgroundColor = computed(() => (pickValue.value ? pickValue.value : options.value.color));
const borderColor = computed(() => lightenDarkenColor(backgroundColor.value as string));

const composeColors = computed(() => {
  if (!props.value || props.value === 'transparent') {
    return '';
  }

  if (isColor(props.value)) {
    return props.value;
  }

  return globalColors.find((col) => col.colorType === props.value)?.color as string;
});

const addSaveColor = () => {
  isChange.value = false; // end change

  if (hasMyColor.value || hasSuggestColor.value) {
    pickValue.value = ''; // clear pick value
    return;
  }

  if (!pickValue.value) return;
  if (myColors.value.length >= MAX_COLOR_SIZE) {
    if (changeIndex.value >= MAX_COLOR_SIZE) {
      changeIndex.value = START_INDEX_REPLACED;
    }

    myColors.value[changeIndex.value] = pickValue.value; // replace color
    changeIndex.value += 1;
  } else {
    myColors.value = [...myColors.value, pickValue.value]; // add color
  }

  pickValue.value = ''; // clear pick value
  setColorToStore();
  save();
};

const setColorToStore = () => {
  globalStyleStore.setCurrentGlobalStyle({
    ...globalStyleStore.currentGlobalStyle!,
    color: {
      ...globalStyleStore.currentGlobalStyle?.color,
      'my-colors': myColors.value as string[],
      'theme-colors': myColors.value as string[],
      'color-index-change': changeIndex.value,
    },
  });

  setGlobalStyle(globalStyleStore.currentGlobalStyle!);
};

const onInputChange = (color: string, whereInput: string) => {
  isChange.value = true;
  pickValue.value = color;

  if (!color || whereInput === 'hexInput') {
    emits('controlChange', props.id, color ? color : 'transparent');
  } else {
    emits('controlOnChange', props.id, color ? color : 'transparent');
  }
};

const onSelected = (color: string) => {
  isChange.value = true;
  pickValue.value = color;
  emits('controlChange', props.id, color ? color : 'transparent');
};

const onOpenPicker = (isShowPicker: boolean) => {
  if (!isShowPicker) {
    setShowColorPreset();
    return;
  }

  const PICKER_HEIGHT = 175;
  if (!floating.value) return;

  const floatingHeight = floating.value.offsetHeight + PICKER_HEIGHT;
  isFixed.value = floatingHeight > spaceBelow.value;
};

const getReferenceSpaceBelow = () => {
  if (!reference.value) return;
  const bounding = reference.value.getBoundingClientRect();
  spaceBelow.value = window.innerHeight - bounding.bottom;
};

const setShowColorPreset = () => {
  showColorPreset.value = true;
  maybeShowColorPreset.value = true;
  globalColor.value = findManyColor();
  myColors.value = [...new Set(globalColor.value)].filter((color) => color !== 'transparent');

  getReferenceSpaceBelow();
  nextTick(() => {
    if (floating.value) {
      const floatingHeight = floating.value.offsetHeight;
      isFixed.value = floatingHeight > spaceBelow.value;
    }
  });
};

const closeColorPreset = () => {
  showColorPreset.value = false;
  if (isChange.value) {
    emits('controlChange', props.id, pickValue.value ? pickValue.value : 'transparent');
  }

  addSaveColor();
};

onClickOutside(floating, (evt) => {
  if (isOpenSelectedColor.value) return;
  evt.stopPropagation();
  closeColorPreset();
});

onUnmounted(() => {
  if (!maybeShowColorPreset.value) return;
  if (onlyAddMore.value) return;

  onlyAddMore.value = true;
  addSaveColor();
});
</script>

<template>
  <div
    ref="reference"
    class="group float-right flex h-36 w-36 cursor-pointer items-center justify-center"
    data-test="editor-control-color-select"
    @click.stop="setShowColorPreset">
    <div
      class="flex h-32 w-32 items-center justify-center rounded-full border border-transparent"
      :class="[
        { '!border-primary-300 ': showColorPreset },
        {
          'border-dark-500': !contained,
          'border-transparent': contained,
        },
        { 'group-hover:border-dark-high group-hover:h-[30px] group-hover:w-[30px]': !showColorPreset },
      ]">
      <div v-if="value && value !== 'transparent'" class="relative h-24 w-24 rounded-full">
        <div
          class="gemx-transparent-bg absolute top-1/2 left-1/2 h-[21px] w-[21px] -translate-x-1/2 -translate-y-1/2 transform rounded-full border"
          :style="{
            'border-color': borderColor,
          }" />
        <div
          :style="{
            'background-color': backgroundColor,
            'border-color': borderColor,
          }"
          class="absolute top-1/2 left-1/2 h-[21px] w-[21px] -translate-x-1/2 -translate-y-1/2 transform rounded-full border" />
      </div>
      <img v-else :src="colorTransparentImg" alt="" />
    </div>
  </div>
  <Teleport to="#root-modal">
    <Transition>
      <div
        v-if="showColorPreset"
        ref="floating"
        :class="{ '!bottom-[16px] !top-auto': isFixed }"
        :style="{
          position: strategy,
          top: y !== null ? `${y}px` : '',
          left: '16px',
        }"
        data-test="editor-control-modal-colors"
        class="bg-dark-400 rounded-medium shadow-control-color-picker left-16 z-[999] w-[312px]">
        <div class="p-12">
          <div class="mb-[17.5px] mt-[1.5px] flex items-center justify-between px-4">
            <div class="text-14 text-dark-high font-medium">Colors</div>
            <button
              data-test="editor-control-modal-colors-close"
              class="rounded-medium flex h-24 w-24 items-center justify-center hover:bg-[#515151]"
              @click="closeColorPreset">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.64645 2.64645C2.84171 2.45118 3.15829 2.45118 3.35355 2.64645L13.3536 12.6464C13.5488 12.8417 13.5488 13.1583 13.3536 13.3536C13.1583 13.5488 12.8417 13.5488 12.6464 13.3536L2.64645 3.35355C2.45118 3.15829 2.45118 2.84171 2.64645 2.64645Z"
                  fill="#E2E2E2" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.3536 2.64645C13.5488 2.84171 13.5488 3.15829 13.3536 3.35355L3.35355 13.3536C3.15829 13.5488 2.84171 13.5488 2.64645 13.3536C2.45118 13.1583 2.45118 12.8417 2.64645 12.6464L12.6464 2.64645C12.8417 2.45118 13.1583 2.45118 13.3536 2.64645Z"
                  fill="#E2E2E2" />
              </svg>
            </button>
          </div>
          <div>
            <GlobalPicker
              :is-auto-update-value="true"
              :value="composeColors"
              @change-color="onInputChange"
              @open-picker="onOpenPicker" />
          </div>
          <MyColorComponent
            :my-colors="myColors.filter((value) => !SUGGESTED_FOR_YOU.includes(value.toUpperCase()))"
            :color="options.color"
            @on-pick-color="onSelected" />
          <SuggestedForYou :colors="SUGGESTED_FOR_YOU" :color="options.color" @on-pick-color="onSelected" />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped>
.gemx-transparent-bg {
  background-image: url('../../../assets/transparent-color.svg');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
