<script setup lang="ts">
import type { OptionSelect } from '@gem/uikit';
import { computed } from 'vue';
import { fontWeightNameMapping, isObjectEqual } from '../../helpers/common';
import type { ScreenType, TypographyV2Attrs, TypographyV2Props } from '../../types';
import TypographyFormEdit from './TypographyFormEdit.vue';
import type { FontItem, TypographyProps, TypographyValue } from './types';
import { capitalizeFirstLetter } from './types';

type Props = {
  id: string;
  sourceTypo?: Record<string, TypographyProps>;
  baseTypo?: TypographyV2Props;
  sourceFont?: Record<string, FontItem>;
  isCustom?: boolean;
  value?: TypographyValue;
  overlayContainer?: string;
  typos?: { id: string; name: string; value?: string }[];
  font?: Record<string, FontItem>;
  fonts?: FontItem[];
  currentScreen?: ScreenType;
  hiddenSetting?: Record<keyof TypographyV2Attrs, boolean>;
};

const props = defineProps<Props>();

const displayValue = computed<TypographyV2Props | undefined>(() => {
  return props.isCustom ? props.value?.custom : props.baseTypo;
});

const displayAttr = computed(() => {
  return props.value?.attrs;
});

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value: Props['value']): void;
  (e: 'controlOnChange', controlId: string, value: Props['value']): void;
  (e: 'changeScreen', screenId: ScreenType): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
  (e: 'changeTypo', type: string): void;
}>();

const typoName = computed(() => {
  return props.typos?.find((typo) => typo.id === props.value?.type)?.name ?? 'Custom';
});

const fontOptions = computed(() => {
  return props.fonts?.map((item) => {
    return {
      label: item.family,
      value: item.family,
      metaData: item.variants,
    };
  });
});

const handleChangeField = (
  name: string,
  value: any,
  propType: 'attrs' | 'custom' = 'custom',
  type: 'controlOnChange' | 'controlChange',
) => {
  const currentPropValue = propType === 'attrs' ? displayAttr.value : displayValue.value;
  const newValue: TypographyValue = {
    ...props.value,
    [propType]: {
      ...currentPropValue,
      [name]: value,
    },
  };
  if (name === 'fontFamily' && propType === 'custom') {
    const font = props.fonts?.find((item) => item.family === value);
    const fontVariants = font?.variants;
    newValue.custom = { ...newValue.custom, fontVariants };
  }
  const { fontVariants, ...restCustom } = newValue.custom ?? {};
  if (isObjectEqual(props.baseTypo, restCustom) && props.value?.type) {
    emit('changeTypo', props.value.type);
  } else {
    if (type === 'controlChange') {
      emit('controlChange', props.id, newValue);
    } else {
      emit('controlOnChange', props.id, newValue);
    }
  }
};

const handleChange = (name: string, value: any, propType: 'attrs' | 'custom' = 'custom') => {
  handleChangeField(name, value, propType, 'controlChange');
};
const handleOnChange = (name: string, value?: any, propType: 'attrs' | 'custom' = 'custom') => {
  handleChangeField(name, value, propType, 'controlOnChange');
};

const changeTypo = (_: OptionSelect, newType: any) => {
  if (newType !== props.value?.type) {
    emit('changeTypo', newType);
  }
};

const getFontWeight = () => {
  const font = props.fonts?.find((item) => item.family === displayValue?.value?.fontFamily);
  return font?.variants
    .map((item) => {
      const value = ['italic', 'regular'].includes(item) ? '400' : item.replace('italic', '');
      return value;
    })
    .filter((c, index, arrs) => {
      return arrs.indexOf(c) === index;
    })
    .map((value) => {
      return {
        label: fontWeightNameMapping[value] ?? value,
        value: value,
      };
    });
};

const fontWeightOptions = computed<{ label: string; value: string }[] | undefined>(getFontWeight);

const fontFamily = computed(() => {
  return Object.entries(props.font || {}).map(([fontKey, fontValue]) => ({
    label: capitalizeFirstLetter(fontKey),
    value: fontKey,
    metaData: fontValue.family,
  }));
});
</script>

<template>
  <div class="flex w-full flex-col gap-16">
    <div class="flex items-center justify-between">
      <span class="text-12 text-dark-low font-regular whitespace-nowrap">Styles</span>
      <div class="max-w-input-horizontal w-full">
        <g-select :options="typos" data-test="editor-control-typo-select" :active-id="value?.type" @select="changeTypo">
          <template #button-content>
            <span
              class="text-12 whitespace-nowrap"
              :class="{
                italic: isCustom,
              }">
              {{ typoName }}{{ isCustom ? '*' : '' }}
            </span>
          </template>
          <template #content="{ item, onSelect, isActive, isHoverSelect }">
            <div
              class="hover:bg-light-100/20 rounded-medium text-light-100 text-12 group relative flex cursor-pointer select-none items-center truncate whitespace-nowrap py-8 pl-24 pr-10 leading-[14px]"
              :class="{ 'bg-light-low !text-dark-high': isActive && !isHoverSelect }"
              :data-value="item.id"
              @click="onSelect(item)">
              <span v-if="isActive" class="absolute inset-y-0 left-4 flex items-center">
                <GBaseIcon name="status-check" width="16" height="16" />
              </span>
              <p class="font-regular flex w-full items-center justify-between truncate">
                <span class="truncate">{{ item.name }}</span>
                <span class="text-dark-low">{{ item.value }}</span>
              </p>
            </div>
          </template>
          <!-- <template #footer>
            <p
              class="text-12 text-primary-200 border-light-500/[0.15] mx-4 cursor-pointer border-t pt-8 pb-4 text-center font-medium leading-[18px]"
              @click.stop="$emit('onClickSubAction', 'editGlobalStyle', 'gs-typography')">
              Edit global style
            </p>
          </template> -->
        </g-select>
      </div>
    </div>

    <TypographyFormEdit
      :font-options="fontOptions"
      :value="displayValue"
      :attrs="displayAttr"
      :overlay-container="overlayContainer"
      :current-screen="currentScreen"
      :font-family="fontFamily"
      :hidden-setting="hiddenSetting"
      :font-weight-options="fontWeightOptions"
      @change="handleChange"
      @on-change="handleOnChange"
      @change-screen="$emit('changeScreen', $event)" />
  </div>
</template>
