<script lang="ts" setup>
import { computed, ref, onUnmounted } from 'vue';
import { GButton } from '@gem/uikit';
import { useThrottleFn } from '@vueuse/core';
import parseUnit, { DEFAULT_UNIT } from '../../helpers/parse-unit';
import type { ScreenUnit } from '../../type/common';

type PropsType = {
  id?: string;
  value?: string;
  placeholder?: string;
  units?: string[];
  readonly?: boolean;
  min?: number;
  max?: number;
  name?: string;
  inputClass?: string;
  useOnlyUnitInit?: boolean;
  hideUnit?: boolean;
  height?: number;
  inputType?: string;
  innerLabel?: string;
  convertNullToMin?: boolean;
  emptyOnClear?: boolean;
  defaultWidth?: string;
};

let inputTimer: ReturnType<typeof setTimeout> | undefined;

const isFocus = ref(false);
const isHover = ref(false);

const props = withDefaults(defineProps<PropsType>(), {
  units: () => ['px', '%'],
  readonly: false,
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  inputType: 'text',
  convertNullToMin: false,
  emptyOnClear: false,
  defaultWidth: '',
});

const latestUnit = ref<ScreenUnit | undefined>((getValueUnit(props?.value) as ScreenUnit) || props.units[0] || 'px');

const inputEl = ref<HTMLInputElement>();

const previousInputValue = ref<string>(props.value ? props.value : '');

const emit = defineEmits<{
  (e: 'onChange', value?: any): void;
  (e: 'change', value?: any, type?: string): void;
}>();

const initValue = (value?: string) => {
  if (value === 'Custom') return value;
  if (value === undefined) return undefined;
  const [textValue, unit] = parseUnit(value);
  if (DEFAULT_UNIT.includes(unit) && props.units.includes(unit)) return textValue;
  if (!DEFAULT_UNIT.includes(unit)) return textValue;
  if (DEFAULT_UNIT.includes(unit) && !props.units.includes(unit)) return value;
  return textValue;
};

function getValueUnit(value?: string) {
  if (value === undefined) return undefined;
  const unit = parseUnit(value)[1];

  if (DEFAULT_UNIT.includes(unit) || !unit) return unit;
  return props.units[0];
}

const inputValue = computed(() => {
  return initValue(props?.value);
});

const heightClass = computed(() => (props.height ? `h-[${props.height}px]` : 'h-[36px]'));

const unit = computed(() => getValueUnit(props?.value));

const defaultWidthValue = computed(() => parseUnit(props.defaultWidth)[0]?.toString());

const checkRangeValue = (value: string | undefined): string | undefined => {
  if (Number(value) > props.max) return props.max.toString();
  if (Number(value) < props.min) return props.min.toString();
  if (props.min !== Number.MIN_SAFE_INTEGER && props.min.toString() && !value && props.convertNullToMin)
    return props.min.toString();
  return value;
};

const controlClick = (inputVal: number) => {
  const newV = Number(inputValue.value) ? Number(inputValue.value) + Number(inputVal) : Number(inputVal);

  const value = checkRangeValue(newV.toString());
  change(`${value}${unit.value}`, inputVal === 1 ? 'plus' : 'minus');
};

const change = useThrottleFn((value: string, unit?: string) => {
  emit('change', value, unit);
}, 100);

const changeUnit = (item: string) => {
  latestUnit.value = item as ScreenUnit;
  emit('change', `${inputValue.value ?? ''}${item}`);
};

const onInputChange = (e: Event) => {
  const inputValue = (e.target as HTMLInputElement).value;
  const [value, unitValue] = parseUnit(inputValue);

  let result = value;
  if (value === undefined) {
    result = undefined;
  } else if (typeof value === 'string') {
    result = value;
  } else {
    if (props.useOnlyUnitInit) {
      result = inputValue;
    } else {
      const unitAppend = unitValue && DEFAULT_UNIT.includes(unitValue) ? unitValue : unit.value;
      result = `${value.toString()}${unitAppend ? unitAppend : 'px'}`;
    }
  }
  emit('onChange', result);
};

onUnmounted(() => {
  if (inputTimer) {
    clearTimeout(inputTimer);
  }
});

const onFocus = (e: Event) => {
  const eValue = (e.target as HTMLInputElement).value;

  isFocus.value = true;
  if (eValue === 'Custom') {
    previousInputValue.value = 'Custom';
  }

  const [value] = parseUnit(eValue);

  if (value != undefined) {
    previousInputValue.value = value.toString();
  }
};

const handleOnInputBlur = (e: Event) => {
  const eValue = (e.target as HTMLInputElement).value;
  if (eValue === 'Custom') return;
  if (eValue.trim() === '') {
    let emitValue = undefined;
    latestUnit.value = getCurrentUnit(eValue);

    if (!props.emptyOnClear) {
      emitValue = defaultWidthValue.value ?? previousInputValue.value;
      if (inputEl.value) {
        inputEl.value.value = `${checkRangeValue(emitValue)}`;
      }
    }

    if (emitValue != 'Custom') {
      emit(
        'change',
        props.useOnlyUnitInit ? checkRangeValue(emitValue) : `${checkRangeValue(emitValue)}${latestUnit.value}`,
      );
    }

    return;
  }
  const [value] = parseUnit(eValue);
  if (value === undefined) {
    emit('change', undefined);
  } else if (typeof value === 'string') {
    latestUnit.value = undefined;
    previousInputValue.value = value;
    emit('change', value);
  } else {
    previousInputValue.value = value.toString();

    if (inputEl.value && (value > props.max || value < props.min || eValue.toString() != value.toString())) {
      inputEl.value.value = `${checkRangeValue(value.toString())}`;
    }

    if (props.useOnlyUnitInit) {
      return emit('change', `${checkRangeValue(value.toString())}`);
    }
    latestUnit.value = getCurrentUnit(eValue);
    emit('change', `${checkRangeValue(value.toString())}${latestUnit.value}`);
  }
};

const getCurrentUnit = (inputValue: string) => {
  const [_, unitValue] = parseUnit(inputValue);
  const unitAppend = unitValue && DEFAULT_UNIT.includes(unitValue) ? unitValue : unit.value;
  return unitAppend ? (unitAppend as ScreenUnit) : 'px';
};

const updateUnit = (newUnit: ScreenUnit | undefined) => {
  latestUnit.value = newUnit;
};

defineExpose({ updateUnit });
</script>

<template>
  <section class="gemx-control gemx-control-input-unit">
    <slot name="label"></slot>
    <div class="gemx-control-bound">
      <div
        class="gemx-control-input-unit_container group relative"
        :class="heightClass"
        @mouseover="isHover = true"
        @mouseleave="isHover = false">
        <input
          v-if="innerLabel"
          :value="innerLabel"
          disabled
          class="text-12 text-dark-disabled rounded-l-medium bg-dark-400 w-full pl-8" />
        <input
          ref="inputEl"
          data-test="editor-control-number-unit-input"
          :value="inputValue"
          :name="name"
          :style="{
            paddingRight: `${units?.length * 28 || 8}px`,
          }"
          class="caret-primary-300 text-12 placeholder:text-dark-disabled focus:!border-primary-300 focus:!bg-dark-400 group-hover:border-dark-200 group-hover:bg-dark-200 disabled:border-dark-200 disabled:bg-dark-200 text-dark-high bg-dark-400 border-dark-400 mr-[-1px] w-full border px-8 outline-none transition-colors duration-200 disabled:cursor-not-allowed"
          :type="inputType"
          :class="[
            {
              'text-center': innerLabel,
              'rounded-medium': !innerLabel,
            },
            `${inputClass} ${heightClass}`,
          ]"
          :placeholder="placeholder"
          :disabled="readonly"
          @focus="onFocus"
          @focusout="isFocus = false"
          @blur="handleOnInputBlur"
          @input="onInputChange"
          @keydown.enter="handleOnInputBlur"
          @keydown.up="controlClick(1)"
          @keydown.down="controlClick(-1)" />
        <div
          v-if="units?.length > 0 && !hideUnit"
          class="text-12 rounded-medium placeholder:text-dark-high disabled:border-dark-200 text-dark-high dark absolute right-0 z-10 flex items-center bg-transparent outline-none transition-colors duration-200 hover:z-[99] disabled:cursor-not-allowed"
          :class="heightClass">
          <template v-if="units?.length > 1">
            <g-button
              v-for="item in units"
              :key="item"
              data-test="editor-control-number-unit-option"
              button-type="button"
              type="secondary"
              class="!text-12 text-dark-disabled hover:dark:!text-light-100 mr-4 flex h-24 w-24 items-center justify-center border-transparent bg-transparent !px-0 font-medium"
              :button-classes="item !== unit ? 'dark:!text-dark-disabled' : 'dark:!text-dark-high'"
              @click="changeUnit(item)"
              >{{ item }}
            </g-button>
          </template>
          <template v-else>
            <div class="w-[30px] py-4 pl-[3px] text-center">
              <span class="!text-12 text-dark-disabled w-10 cursor-default font-medium" :class="heightClass">
                {{ units[0] }}
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.gemx-control-input-unit {
  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  :-moz-placeholder,
  :-ms-input-placeholder {
    color: #7f7f7f;
  }

  .gemx-control-bound {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    transition: all 0.25s;

    .gemx-control-input-unit_container {
      position: relative;
      display: flex;
      width: 100%;
    }

    input {
      z-index: 5;

      &:focus {
        z-index: 7;
      }
    }
  }
}
</style>
